import React, { useState, useEffect } from "react";
import Layout from "../components/Global/layout";
import {
  ArrowRightIcon,
  ChevronRightIcon,
  DownloadIcon,
} from "@heroicons/react/solid";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import SEO from "../components/seo/SEO";

const IndexPage = () => {
  return (
    <>
      <SEO />
      <Layout>
        {/* <section className = "w-screen h-[640px] aspect-w-16 asepct-h-9 relative">
          <StaticImage 
          src = "https://res.cloudinary.com/kabira-mobility/image/upload/v1638764775/Landing%20Page/LandingPage02_hi4wlx.png"
          alt = "KM3000 & KM4000"
          className = "z-10"/>
          <div className = "absolute">
            <h1 className = "uppercase italic font-black text-blue-500 text-9xl leading-tight text-center">Talk Less <br /> Do More</h1>
          </div>
        </section> */}
        <main>
          {/* ++++++++++++++++++++++
HEADER IMAGE
++++++++++++++++++++++ */}

          {/* XS HEADER */}
          <div className="aspect-w-5 aspect-h-11 relative bg-[#101010] sm:hidden pb-0">
            <StaticImage
              src="https://res.cloudinary.com/kabira-mobility/image/upload/v1638803745/Landing%20Page/FrameXS_Header_04_fq5gya.png"
              alt="KM3000 & KM4000"
              className="aspect-w-5 aspect-h-11 sm:hidden"
            />
            <div className="absolute pt-8 xl:pt-0 inset-y-8 xl:inset-y-16 h-full flex flex-col justify-between items-center">
              <h1 className="-z-10 uppercase italic text-transparent font-black bg-clip-text text-shadow-xl bg-gradient-to-br from-blue-500 to-green-500 text-6xl 2xl:text-8xl leading-none text-center text-shadow-lg">
                Talk Less. <br />{" "}
                <span className="text-5xl 2xl:text-7xl">Do More.</span>
              </h1>
              <ul className="absolute origin-bottom bottom-0 h-[240px] flex flex-col space-y-4 items-center z-20 xl:mb-12">
                <li className="text-2xl font-medium text-gray-400">
                  Test Rides Starting From
                </li>
                <li className="text-5xl text-blue-500 font-black italic">
                  18/12/2021
                </li>

                <Link to="test-rides">
                  <li className="group">
                    <button className="flex flex-row space-x-4 p-4 border border-blue-500 group-hover:bg-blue-500 rounded-md items-center">
                      <p className="text-xl text-blue-500 group-hover:text-gray-800">
                        Register now
                      </p>
                      <ChevronRightIcon className="h-6 text-blue-500 group-hover:text-gray-800" />
                    </button>
                  </li>
                </Link>
              </ul>
            </div>
          </div>

          {/* SM HEADER */}
          <div className="aspect-w-9 aspect-h-11 relative bg-[#101010] hidden sm:block md:hidden pb-0">
            <StaticImage
              src="https://res.cloudinary.com/kabira-mobility/image/upload/v1638803745/Landing%20Page/FrameSM_Header_04_qvnh2w.png"
              alt="KM3000 & KM4000"
              className="aspect-w-9 aspect-h-11 hidden sm:block md:hidden"
            />
            <div className="absolute mt-8 xl:mt-0 inset-y-8 xl:inset-y-16 h-full flex flex-col justify-between items-center">
              <h1 className="-z-10 uppercase italic text-transparent font-black bg-clip-text text-shadow-xl bg-gradient-to-br from-blue-500 to-green-500 text-6xl 2xl:text-8xl leading-none text-center text-shadow-lg">
                Talk Less. <br />{" "}
                <span className="text-5xl 2xl:text-7xl">Do More.</span>
              </h1>
              <ul className="absolute origin-bottom bottom-0 h-[240px] flex flex-col space-y-4 items-center z-20 xl:mb-12">
                <li className="text-2xl font-medium text-gray-400">
                  Test Rides Starting From
                </li>
                <li className="text-5xl text-blue-500 font-black italic">
                  18/12/2021
                </li>
                <Link to="test-rides">
                  <li className="group">
                    <button className="flex flex-row space-x-4 p-4 border border-blue-500 group-hover:bg-blue-500 rounded-md items-center">
                      <p className="text-xl text-blue-500 group-hover:text-gray-800">
                        Register now
                      </p>
                      <ChevronRightIcon className="h-6 text-blue-500 group-hover:text-gray-800" />
                    </button>
                  </li>
                </Link>
              </ul>
            </div>
          </div>

          {/* MD HEADER */}
          <div className="aspect-w-4 aspect-h-4 relative bg-[#101010] hidden md:block lg:hidden pb-0">
            <StaticImage
              src="https://res.cloudinary.com/kabira-mobility/image/upload/v1638803745/Landing%20Page/FrameMD_Header_04_csf4mw.png"
              alt="KM3000 & KM4000"
              className="aspect-w-4 aspect-h-4 hidden md:block lg:hidden"
            />
            <div className="absolute mt-8 xl:mt-0 inset-y-8 xl:inset-y-16 h-full flex flex-col justify-between items-center">
              <h1 className="-z-10 uppercase italic text-transparent font-black bg-clip-text text-shadow-xl bg-gradient-to-br from-blue-500 to-green-500 text-6xl 2xl:text-8xl leading-none text-center text-shadow-lg">
                Talk Less. <br />{" "}
                <span className="text-5xl 2xl:text-7xl">Do More.</span>
              </h1>
              <ul className="absolute origin-bottom bottom-0 h-[240px] flex flex-col space-y-4 items-center z-20 xl:mb-12">
                <li className="text-2xl font-medium text-gray-400">
                  Test Rides Starting From
                </li>
                <li className="text-5xl text-blue-500 font-black italic">
                  18/12/2021
                </li>
                <Link to="test-rides">
                  <li className="group">
                    <button className="flex flex-row space-x-4 p-4 border border-blue-500 group-hover:bg-blue-500 rounded-md items-center">
                      <p className="text-xl text-blue-500 group-hover:text-gray-800">
                        Register now
                      </p>
                      <ChevronRightIcon className="h-6 text-blue-500 group-hover:text-gray-800" />
                    </button>
                  </li>
                </Link>
              </ul>
            </div>
          </div>
          {/* LG HEADER */}
          <div className="aspect-w-16 aspect-h-10 relative bg-[#101010] hidden lg:block xl:hidden pb-0">
            <StaticImage
              src="https://res.cloudinary.com/kabira-mobility/image/upload/v1638803745/Landing%20Page/FrameLG_Header_04_zernr5.png"
              alt="KM3000 & KM4000"
              className="aspect-w-16 aspect-h-10 hidden lg:block xl:hidden"
            />
            <div className="absolute mt-8 xl:mt-0 inset-y-8 xl:inset-y-16 h-full flex flex-col justify-between items-center">
              <h1 className="-z-10 uppercase italic text-transparent font-black bg-clip-text text-shadow-xl bg-gradient-to-br from-blue-500 to-green-500 text-6xl 2xl:text-8xl leading-none text-center text-shadow-lg">
                Talk Less. <br />{" "}
                <span className="text-5xl 2xl:text-7xl">Do More.</span>
              </h1>
              <ul className="absolute origin-bottom bottom-0 h-[240px] flex flex-col space-y-4 items-center z-20 xl:mb-12">
                <li className="text-2xl font-medium text-gray-400">
                  Test Rides Starting From
                </li>
                <li className="text-5xl text-blue-500 font-black italic">
                  18/12/2021
                </li>
                <Link to="test-rides">
                  <li className="group">
                    <button className="flex flex-row space-x-4 p-4 border border-blue-500 group-hover:bg-blue-500 rounded-md items-center">
                      <p className="text-xl text-blue-500 group-hover:text-gray-800">
                        Register now
                      </p>
                      <ChevronRightIcon className="h-6 text-blue-500 group-hover:text-gray-800" />
                    </button>
                  </li>
                </Link>
              </ul>
            </div>
          </div>
          {/* XL HEADER */}
          <div className="aspect-w-16 aspect-h-8 relative bg-[#101010] hidden xl:block pb-0">
            <StaticImage
              // src="https://res.cloudinary.com/kabira-mobility/image/upload/v1638803745/Landing%20Page/Frame2XL_Header_04_pczkfe.png"
              src="https://res.cloudinary.com/kabira-mobility/image/upload/v1638766791/Landing%20Page/LandingPage04_vkxudg.png"
              alt="KM3000 & KM4000"
              className="aspect-w-16 aspect-h-8 hidden xl:block"
            />
            <div className="absolute mt-8 xl:mt-0 inset-y-8 xl:inset-y-16 h-full flex flex-col justify-between items-center">
              <h1 className="-z-10 uppercase italic text-transparent font-black bg-clip-text text-shadow-xl bg-gradient-to-br from-blue-500 to-green-500 text-6xl 2xl:text-8xl leading-none text-center text-shadow-lg">
                Talk Less. <br />{" "}
                <span className="text-5xl 2xl:text-7xl">Do More.</span>
              </h1>
              <ul className="absolute origin-bottom bottom-0 h-[240px] flex flex-col space-y-4 items-center z-20 xl:mb-12">
                <li className="text-2xl font-medium text-gray-400">
                  Test Rides Starting From
                </li>
                <li className="text-5xl text-blue-500 font-black italic">
                  18/12/2021
                </li>
                <Link to="test-rides">
                  <li className="group">
                    <button className="flex flex-row space-x-4 p-4 border border-blue-500 group-hover:bg-blue-500 rounded-md items-center">
                      <p className="text-xl text-blue-500 group-hover:text-gray-800">
                        Register now
                      </p>
                      <ChevronRightIcon className="h-6 text-blue-500 group-hover:text-gray-800" />
                    </button>
                  </li>
                </Link>
              </ul>
            </div>
          </div>

          {/* ++++++++++++++++++++++
KM3000 IMAGE
++++++++++++++++++++++ */}

          {/* XS 3000 */}

          <div className="aspect-w-5 aspect-h-11 relative bg-[#131313] sm:hidden pb-0">
            <StaticImage
              src="https://res.cloudinary.com/kabira-mobility/image/upload/v1638804709/Landing%20Page/FrameXS_KM3000_7_q9ikba.png"
              alt="KM3000 & KM4000"
              className="aspect-w-5 aspect-h-11 sm:hidden"
            />
            <div className="absolute xl:grid xl:grid-cols-2 xl:grid-rows-2 xl:inset-32 xl:h-auto xl:w-auto">
              <ul className="flex flex-col space-y-4 xl:col-start-2 xl:row-span-2 justify-between">
                <li className="flex flex-col space-y-2 pb-8 xl:pb-0 pt-12 xl:pt-0">
                  <h1 className="text-4xl sm:text-5xl xl:text-6xl font-extrabold italic text-blue-500 xl:text-right text-center">
                    KM3000
                  </h1>
                  <p className="text-xl xl:text-2xl font-medium xl:font-semibold italic text-gray-400 xl:text-right text-center">
                    Performance Redefined
                  </p>
                </li>
                <ul className="flex flex-row mx-auto xl:mx-none xl:w-full xl:flex-col xl:justify-end space-x-6 xl:space-x-0 sm:space-x-12 xl:space-y-5 xl:pt-12">
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-right">
                      120km
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-right">
                      Range
                    </p>
                  </li>
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-right">
                      100kmph
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-right">
                      Top Speed
                    </p>
                  </li>
                  <li className="hidden sm:flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-right">
                      3.2s
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-right">
                      0 - 60kmph
                    </p>
                  </li>
                </ul>
              </ul>
            </div>
          </div>
          {/* SM 3000 */}

          <div className="aspect-w-9 aspect-h-11 relative bg-[#131313] hidden sm:block md:hidden pb-0">
            <StaticImage
              src="https://res.cloudinary.com/kabira-mobility/image/upload/v1638804709/Landing%20Page/FrameSM_KM3000_7_o8hfug.png"
              alt="KM3000 & KM4000"
              className="aspect-w-9 aspect-h-11 hidden sm:block md:hidden"
            />
            <div className="absolute xl:grid xl:grid-cols-2 xl:grid-rows-2 xl:inset-32 xl:h-auto xl:w-auto">
              <ul className="flex flex-col space-y-4 xl:col-start-2 xl:row-span-2 justify-between">
                <li className="flex flex-col space-y-2 pb-8 xl:pb-0 pt-12 xl:pt-0">
                  <h1 className="text-4xl sm:text-5xl xl:text-6xl font-extrabold italic text-blue-500 xl:text-right text-center">
                    KM3000
                  </h1>
                  <p className="text-xl xl:text-2xl font-medium xl:font-semibold italic text-gray-400 xl:text-right text-center">
                    Performance Redefined
                  </p>
                </li>
                <ul className="flex flex-row mx-auto xl:mx-none xl:w-full xl:flex-col xl:justify-end space-x-6 xl:space-x-0 sm:space-x-12 xl:space-y-5 xl:pt-12">
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-right">
                      120km
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-right">
                      Range
                    </p>
                  </li>
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-right">
                      100kmph
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-right">
                      Top Speed
                    </p>
                  </li>
                  <li className="hidden sm:flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-right">
                      3.2s
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-right">
                      0 - 60kmph
                    </p>
                  </li>
                </ul>
              </ul>
            </div>
          </div>
          {/* MD 3000 */}

          <div className="aspect-w-4 aspect-h-4 relative bg-[#131313] hidden md:block lg:hidden pb-0">
            <StaticImage
              src="https://res.cloudinary.com/kabira-mobility/image/upload/v1638804709/Landing%20Page/FrameMD_KM3000_7_bxomxw.png"
              alt="KM3000 & KM4000"
              className="aspect-w-4 aspect-h-4 hidden md:block lg:hidden"
            />
            <div className="absolute xl:grid xl:grid-cols-2 xl:grid-rows-2 xl:inset-32 xl:h-auto xl:w-auto">
              <ul className="flex flex-col space-y-4 xl:col-start-2 xl:row-span-2 justify-between">
                <li className="flex flex-col space-y-2 pb-8 xl:pb-0 pt-12 xl:pt-0">
                  <h1 className="text-4xl sm:text-5xl xl:text-6xl font-extrabold italic text-blue-500 xl:text-right text-center">
                    KM3000
                  </h1>
                  <p className="text-xl xl:text-2xl font-medium xl:font-semibold italic text-gray-400 xl:text-right text-center">
                    Performance Redefined
                  </p>
                </li>
                <ul className="flex flex-row mx-auto xl:mx-none xl:w-full xl:flex-col xl:justify-end space-x-6 xl:space-x-0 sm:space-x-12 xl:space-y-5 xl:pt-12">
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-right">
                      120km
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-right">
                      Range
                    </p>
                  </li>
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-right">
                      100kmph
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-right">
                      Top Speed
                    </p>
                  </li>
                  <li className="hidden sm:flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-right">
                      3.2s
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-right">
                      0 - 60kmph
                    </p>
                  </li>
                </ul>
              </ul>
            </div>
          </div>
          {/* LG 3000 */}

          <div className="aspect-w-16 aspect-h-10 relative bg-[#131313] hidden lg:block xl:hidden pb-0">
            <StaticImage
              src="https://res.cloudinary.com/kabira-mobility/image/upload/v1638804709/Landing%20Page/FrameLG_KM3000_7_hm8xwa.png"
              alt="KM3000 & KM4000"
              className="aspect-w-16 aspect-h-10 hidden lg:block xl:hidden"
            />
            <div className="absolute xl:grid xl:grid-cols-2 xl:grid-rows-2 xl:inset-32 xl:h-auto xl:w-auto">
              <ul className="flex flex-col space-y-4 xl:col-start-2 xl:row-span-2 justify-between">
                <li className="flex flex-col space-y-2 pb-8 xl:pb-0 pt-12 xl:pt-0">
                  <h1 className="text-4xl sm:text-5xl xl:text-6xl font-extrabold italic text-blue-500 xl:text-right text-center">
                    KM3000
                  </h1>
                  <p className="text-xl xl:text-2xl font-medium xl:font-semibold italic text-gray-400 xl:text-right text-center">
                    Performance Redefined
                  </p>
                </li>
                <ul className="flex flex-row mx-auto xl:mx-none xl:w-full xl:flex-col xl:justify-end space-x-6 xl:space-x-0 sm:space-x-12 xl:space-y-5 xl:pt-12">
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-right">
                      120km
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-right">
                      Range
                    </p>
                  </li>
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-right">
                      100kmph
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-right">
                      Top Speed
                    </p>
                  </li>
                  <li className="hidden sm:flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-right">
                      3.2s
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-right">
                      0 - 60kmph
                    </p>
                  </li>
                </ul>
              </ul>
            </div>
          </div>
          {/* XL 3000 */}

          <div className="aspect-w-16 aspect-h-8 relative bg-[#131313] hidden xl:block pb-0">
            <StaticImage
              src="https://res.cloudinary.com/kabira-mobility/image/upload/v1638804708/Landing%20Page/Frame2XL_KM3000_7_kb0min.png"
              alt="KM3000 & KM4000"
              className="aspect-w-16 aspect-h-8 xl:block"
            />
            <div className="absolute xl:grid xl:grid-cols-2 xl:grid-rows-2 xl:inset-32 xl:h-auto xl:w-auto">
              <ul className="flex flex-col space-y-4 xl:col-start-2 xl:row-span-2 justify-between">
                <li className="flex flex-col space-y-2 pb-8 xl:pb-0 pt-12 xl:pt-0">
                  <h1 className="text-4xl sm:text-5xl xl:text-6xl font-extrabold italic text-blue-500 xl:text-right text-center">
                    KM3000
                  </h1>
                  <p className="text-xl xl:text-2xl font-medium xl:font-semibold italic text-gray-400 xl:text-right text-center">
                    Performance Redefined
                  </p>
                </li>
                <ul className="flex flex-row mx-auto xl:mx-none xl:w-full xl:flex-col xl:justify-end space-x-6 xl:space-x-0 sm:space-x-12 xl:space-y-5 xl:pt-12">
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-right">
                      120km
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-right">
                      Range
                    </p>
                  </li>
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-right">
                      100kmph
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-right">
                      Top Speed
                    </p>
                  </li>
                  <li className="hidden sm:flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-right">
                      3.2s
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-right">
                      0 - 60kmph
                    </p>
                  </li>
                </ul>
              </ul>
            </div>
          </div>

          {/* ++++++++++++++++++++++
KM40000 IMAGE
++++++++++++++++++++++ */}

          {/* XS 4000 */}

          <div className="aspect-w-5 aspect-h-11 relative bg-[#101010] sm:hidden pb-0">
            <StaticImage
              src="https://res.cloudinary.com/kabira-mobility/image/upload/v1638805254/Landing%20Page/FrameXS_KM4000_8_apjzhm.png"
              alt="KM3000 & KM4000"
              className="aspect-w-5 aspect-h-11 sm:hidden"
            />
            <div className="absolute xl:grid xl:grid-cols-2 xl:grid-rows-2 xl:inset-32 xl:h-auto xl:w-auto">
              <ul className="flex flex-col space-y-4 xl:col-start-1 xl:row-span-2 justify-between">
                <li className="flex flex-col space-y-2 pb-8 xl:pb-0 pt-12 xl:pt-0">
                  <h1 className="text-4xl sm:text-5xl xl:text-6xl font-extrabold italic text-blue-500 xl:text-left text-center">
                    KM4000
                  </h1>
                  <p className="text-xl xl:text-2xl font-medium xl:font-semibold italic text-gray-400 xl:text-left text-center">
                    The Silent Warrior
                  </p>
                </li>
                <ul className="flex flex-row mx-auto xl:mx-none xl:w-full xl:flex-col xl:justify-end space-x-6 xl:space-x-0 sm:space-x-12 xl:space-y-5 xl:pt-12">
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-left">
                      150km
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-left">
                      Range
                    </p>
                  </li>
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-left">
                      120kmph
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-left">
                      Top Speed
                    </p>
                  </li>
                  <li className="hidden sm:flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-left">
                      3.1s
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-left">
                      0 - 60kmph
                    </p>
                  </li>
                </ul>
              </ul>
            </div>
          </div>
          {/* SM HEADER */}

          <div className="aspect-w-9 aspect-h-11 relative bg-[#101010] hidden sm:block md:hidden pb-0">
            <StaticImage
              src="https://res.cloudinary.com/kabira-mobility/image/upload/v1638805254/Landing%20Page/FrameSM_KM4000_8_paefsf.png"
              alt="KM3000 & KM4000"
              className="aspect-w-9 aspect-h-11 hidden sm:block md:hidden"
            />
            <div className="absolute xl:grid xl:grid-cols-2 xl:grid-rows-2 xl:inset-32 xl:h-auto xl:w-auto">
              <ul className="flex flex-col space-y-4 xl:col-start-1 xl:row-span-2 justify-between">
                <li className="flex flex-col space-y-2 pb-8 xl:pb-0 pt-12 xl:pt-0">
                  <h1 className="text-4xl sm:text-5xl xl:text-6xl font-extrabold italic text-blue-500 xl:text-left text-center">
                    KM4000
                  </h1>
                  <p className="text-xl xl:text-2xl font-medium xl:font-semibold italic text-gray-400 xl:text-left text-center">
                    The Silent Warrior
                  </p>
                </li>
                <ul className="flex flex-row mx-auto xl:mx-none xl:w-full xl:flex-col xl:justify-end space-x-6 xl:space-x-0 sm:space-x-12 xl:space-y-5 xl:pt-12">
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-left">
                      150km
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-left">
                      Range
                    </p>
                  </li>
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-left">
                      120kmph
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-left">
                      Top Speed
                    </p>
                  </li>
                  <li className="hidden sm:flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-left">
                      3.1s
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-left">
                      0 - 60kmph
                    </p>
                  </li>
                </ul>
              </ul>
            </div>
          </div>
          {/* MD HEADER */}

          <div className="aspect-w-4 aspect-h-4 relative bg-[#101010] hidden md:block lg:hidden pb-0">
            <StaticImage
              src="https://res.cloudinary.com/kabira-mobility/image/upload/v1638805254/Landing%20Page/FrameMD_KM4000_8_qvex4q.png"
              alt="KM3000 & KM4000"
              className="aspect-w-4 aspect-h-4 hidden md:block lg:hidden"
            />
            <div className="absolute xl:grid xl:grid-cols-2 xl:grid-rows-2 xl:inset-32 xl:h-auto xl:w-auto">
              <ul className="flex flex-col space-y-4 xl:col-start-1 xl:row-span-2 justify-between">
                <li className="flex flex-col space-y-2 pb-8 xl:pb-0 pt-12 xl:pt-0">
                  <h1 className="text-4xl sm:text-5xl xl:text-6xl font-extrabold italic text-blue-500 xl:text-left text-center">
                    KM4000
                  </h1>
                  <p className="text-xl xl:text-2xl font-medium xl:font-semibold italic text-gray-400 xl:text-left text-center">
                    The Silent Warrior
                  </p>
                </li>
                <ul className="flex flex-row mx-auto xl:mx-none xl:w-full xl:flex-col xl:justify-end space-x-6 xl:space-x-0 sm:space-x-12 xl:space-y-5 xl:pt-12">
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-left">
                      150km
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-left">
                      Range
                    </p>
                  </li>
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-left">
                      120kmph
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-left">
                      Top Speed
                    </p>
                  </li>
                  <li className="hidden sm:flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-left">
                      3.1s
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-left">
                      0 - 60kmph
                    </p>
                  </li>
                </ul>
              </ul>
            </div>
          </div>
          {/* LG HEADER */}

          <div className="aspect-w-16 aspect-h-10 relative bg-[#101010] hidden lg:block xl:hidden pb-0">
            <StaticImage
              src="https://res.cloudinary.com/kabira-mobility/image/upload/v1638805255/Landing%20Page/FrameLG_KM4000_8_aobaao.png"
              alt="KM3000 & KM4000"
              className="aspect-w-16 aspect-h-10 hidden lg:block xl:hidden"
            />
            <div className="absolute xl:grid xl:grid-cols-2 xl:grid-rows-2 xl:inset-32 xl:h-auto xl:w-auto">
              <ul className="flex flex-col space-y-4 xl:col-start-1 xl:row-span-2 justify-between">
                <li className="flex flex-col space-y-2 pb-8 xl:pb-0 pt-12 xl:pt-0">
                  <h1 className="text-4xl sm:text-5xl xl:text-6xl font-extrabold italic text-blue-500 xl:text-left text-center">
                    KM4000
                  </h1>
                  <p className="text-xl xl:text-2xl font-medium xl:font-semibold italic text-gray-400 xl:text-left text-center">
                    The Silent Warrior
                  </p>
                </li>
                <ul className="flex flex-row mx-auto xl:mx-none xl:w-full xl:flex-col xl:justify-end space-x-6 xl:space-x-0 sm:space-x-12 xl:space-y-5 xl:pt-12">
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-left">
                      150km
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-left">
                      Range
                    </p>
                  </li>
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-left">
                      120kmph
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-left">
                      Top Speed
                    </p>
                  </li>
                  <li className="hidden sm:flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-left">
                      3.1s
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-left">
                      0 - 60kmph
                    </p>
                  </li>
                </ul>
              </ul>
            </div>
          </div>
          {/* XL HEADER */}

          <div className="aspect-w-16 aspect-h-8 relative bg-[#101010] hidden xl:block pb-0">
            <StaticImage
              src="https://res.cloudinary.com/kabira-mobility/image/upload/v1638805260/Landing%20Page/Frame2XL_KM3000_8_t2eevu.png"
              alt="KM3000 & KM4000"
              className="aspect-w-16 aspect-h-8 hidden xl:block"
            />
            <div className="absolute xl:grid xl:grid-cols-2 xl:grid-rows-2 xl:inset-32 xl:h-auto xl:w-auto">
              <ul className="flex flex-col space-y-4 xl:col-start-1 xl:row-span-2 justify-between">
                <li className="flex flex-col space-y-2 pb-8 xl:pb-0 pt-12 xl:pt-0">
                  <h1 className="text-4xl sm:text-5xl xl:text-6xl font-extrabold italic text-blue-500 xl:text-left text-center">
                    KM4000
                  </h1>
                  <p className="text-xl xl:text-2xl font-medium xl:font-semibold italic text-gray-400 xl:text-left text-center">
                    The Silent Warrior
                  </p>
                </li>
                <ul className="flex flex-row mx-auto xl:mx-none xl:w-full xl:flex-col xl:justify-end space-x-6 xl:space-x-0 sm:space-x-12 xl:space-y-5 xl:pt-12">
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-left">
                      150km
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-left">
                      Range
                    </p>
                  </li>
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-left">
                      120kmph
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-left">
                      Top Speed
                    </p>
                  </li>
                  <li className="hidden sm:flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-left">
                      3.1s
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-left">
                      0 - 60kmph
                    </p>
                  </li>
                </ul>
              </ul>
            </div>
          </div>

          {/* ++++++++++++++++++++++
CHARGING
++++++++++++++++++++++ */}

          {/* XS CONTROLLER */}
          <div className="aspect-w-5 aspect-h-11 relative bg-[#131313] sm:hidden pb-0">
            <StaticImage
              src="https://res.cloudinary.com/kabira-mobility/image/upload/v1638834198/Landing%20Page/Charging_5-11_f3caah.png"
              alt="KM3000 & KM4000"
              className="aspect-w-5 aspect-h-11 sm:hidden"
            />
            <div className="absolute xl:grid xl:grid-cols-2 xl:grid-rows-2 xl:inset-32 xl:h-auto xl:w-auto">
              <ul className="flex flex-col space-y-4 xl:col-start-2 xl:row-span-2 justify-between">
                <li className="flex flex-col space-y-2 pb-8 xl:pb-0 pt-12 xl:pt-0">
                  <h1 className="text-4xl sm:text-5xl xl:text-6xl xl:leading-tight uppercase font-extrabold italic text-blue-500 xl:text-right text-center">
                    Fast Charge <br />{" "}
                    <span className="text-3xl sm:text-4xl xl:text-5xl">
                      Anytime. Anywhere.
                    </span>
                  </h1>
                </li>
                <ul className="flex flex-row mx-auto xl:mx-none xl:w-full xl:flex-col xl:justify-end space-x-6 xl:space-x-0 sm:space-x-12 xl:space-y-5 xl:pt-12">
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-right">
                      80%
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-right">
                      in 50mins
                    </p>
                  </li>
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-right">
                      Fast Charge
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-right">
                      Supported Accessory
                    </p>
                  </li>
                </ul>
              </ul>
            </div>
          </div>
          {/* SM CONTROLLER */}
          <div className="aspect-w-9 aspect-h-11 relative bg-[#131313] hidden sm:block md:hidden pb-0">
            <StaticImage
              src="https://res.cloudinary.com/kabira-mobility/image/upload/v1638834198/Landing%20Page/Charging_9-11_fmi13x.png"
              alt="KM3000 & KM4000"
              className="aspect-w-9 aspect-h-11 hidden sm:block md:hidden"
            />
            <div className="absolute xl:grid xl:grid-cols-2 xl:grid-rows-2 xl:inset-32 xl:h-auto xl:w-auto">
              <ul className="flex flex-col space-y-4 xl:col-start-2 xl:row-span-2 justify-between">
                <li className="flex flex-col space-y-2 pb-8 xl:pb-0 pt-12 xl:pt-0">
                  <h1 className="text-4xl sm:text-5xl xl:text-6xl xl:leading-tight uppercase font-extrabold italic text-blue-500 xl:text-right text-center">
                    Fast Charge <br />{" "}
                    <span className="text-3xl sm:text-4xl xl:text-5xl">
                      Anytime. Anywhere.
                    </span>
                  </h1>
                </li>
                <ul className="flex flex-row mx-auto xl:mx-none xl:w-full xl:flex-col xl:justify-end space-x-6 xl:space-x-0 sm:space-x-12 xl:space-y-5 xl:pt-12">
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-right">
                      80%
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-right">
                      in 50mins
                    </p>
                  </li>
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-right">
                      Fast Charge
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-right">
                      Supported Accessory
                    </p>
                  </li>
                </ul>
              </ul>
            </div>
          </div>
          {/* MD CONTROLLER */}
          <div className="aspect-w-4 aspect-h-4 relative bg-[#131313] hidden md:block lg:hidden pb-0">
            <StaticImage
              src="https://res.cloudinary.com/kabira-mobility/image/upload/v1638834199/Landing%20Page/Charging_-_01-01_wpd2gm.png"
              alt="KM3000 & KM4000"
              className="aspect-w-4 aspect-h-4 hidden md:block lg:hidden"
            />
            <div className="absolute xl:grid xl:grid-cols-2 xl:grid-rows-2 xl:inset-32 xl:h-auto xl:w-auto">
              <ul className="flex flex-col space-y-4 xl:col-start-2 xl:row-span-2 justify-between">
                <li className="flex flex-col space-y-2 pb-8 xl:pb-0 pt-12 xl:pt-0">
                  <h1 className="text-4xl sm:text-5xl xl:text-6xl xl:leading-tight uppercase font-extrabold italic text-blue-500 xl:text-right text-center">
                    Fast Charge <br />{" "}
                    <span className="text-3xl sm:text-4xl xl:text-5xl">
                      Anytime. Anywhere.
                    </span>
                  </h1>
                </li>
                <ul className="flex flex-row mx-auto xl:mx-none xl:w-full xl:flex-col xl:justify-end space-x-6 xl:space-x-0 sm:space-x-12 xl:space-y-5 xl:pt-12">
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-right">
                      80%
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-right">
                      in 50mins
                    </p>
                  </li>
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-right">
                      Fast Charge
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-right">
                      Supported Accessory
                    </p>
                  </li>
                </ul>
              </ul>
            </div>
          </div>
          {/* LG CONTROLLER */}
          <div className="aspect-w-16 aspect-h-10 relative bg-[#131313] hidden lg:block xl:hidden pb-0">
            <StaticImage
              src="https://res.cloudinary.com/kabira-mobility/image/upload/v1638834551/Landing%20Page/16-10_Charging_z0fjza.png"
              alt="KM3000 & KM4000"
              className="aspect-w-16 aspect-h-10 hidden lg:block xl:hidden"
            />
            <div className="absolute xl:grid xl:grid-cols-2 xl:grid-rows-2 xl:inset-32 xl:h-auto xl:w-auto">
              <ul className="flex flex-col space-y-4 xl:col-start-2 xl:row-span-2 justify-between">
                <li className="flex flex-col space-y-2 pb-8 xl:pb-0 pt-12 xl:pt-0">
                  <h1 className="text-4xl sm:text-5xl xl:text-6xl xl:leading-tight uppercase font-extrabold italic text-blue-500 xl:text-right text-center">
                    Fast Charge <br />{" "}
                    <span className="text-3xl sm:text-4xl xl:text-5xl">
                      Anytime. Anywhere.
                    </span>
                  </h1>
                </li>
                <ul className="flex flex-row mx-auto xl:mx-none xl:w-full xl:flex-col xl:justify-end space-x-6 xl:space-x-0 sm:space-x-12 xl:space-y-5 xl:pt-12">
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-right">
                      80%
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-right">
                      in 50mins
                    </p>
                  </li>
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-right">
                      Fast Charge
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-right">
                      Supported Accessory
                    </p>
                  </li>
                </ul>
              </ul>
            </div>
          </div>
          <div className="aspect-w-16 aspect-h-8 relative bg-[#131313] hidden lg:block xl:hidden pb-0">
            <StaticImage
              src="https://res.cloudinary.com/kabira-mobility/image/upload/v1638834551/Landing%20Page/16-10_Charging_z0fjza.png"
              alt="KM3000 & KM4000"
              className="aspect-w-16 aspect-h-8 hidden lg:block xl:hidden"
            />
            <div className="absolute xl:grid xl:grid-cols-2 xl:grid-rows-2 xl:inset-32 xl:h-auto xl:w-auto">
              <ul className="flex flex-col space-y-4 xl:col-start-2 xl:row-span-2 justify-between">
                <li className="flex flex-col space-y-2 pb-8 xl:pb-0 pt-12 xl:pt-0">
                  <h1 className="text-4xl sm:text-5xl xl:text-6xl xl:leading-tight uppercase font-extrabold italic text-blue-500 xl:text-right text-center">
                    Fast Charge <br />{" "}
                    <span className="text-3xl sm:text-4xl xl:text-5xl">
                      Anytime. Anywhere.
                    </span>
                  </h1>
                </li>
                <ul className="flex flex-row mx-auto xl:mx-none xl:w-full xl:flex-col xl:justify-end space-x-6 xl:space-x-0 sm:space-x-12 xl:space-y-5 xl:pt-12">
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-right">
                      80%
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-right">
                      in 50mins
                    </p>
                  </li>
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-right">
                      Fast Charge
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-right">
                      Supported Accessory
                    </p>
                  </li>
                </ul>
              </ul>
            </div>
          </div>
          <div className="aspect-w-16 aspect-h-8 relative bg-[#131313] hidden xl:block pb-0">
            <StaticImage
              src="https://res.cloudinary.com/kabira-mobility/image/upload/v1638834198/Landing%20Page/Charging._-_16-08_rl1ror.png"
              alt="KM3000 & KM4000"
              className="aspect-w-16 aspect-h-8 hidden xl:block"
            />
            <div className="absolute xl:grid xl:grid-cols-2 xl:grid-rows-2 xl:inset-32 xl:h-auto xl:w-auto">
              <ul className="flex flex-col space-y-4 xl:col-start-2 xl:row-span-2 justify-between">
                <li className="flex flex-col space-y-2 pb-8 xl:pb-0 pt-12 xl:pt-0">
                  <h1 className="text-4xl sm:text-5xl xl:text-6xl xl:leading-tight uppercase font-extrabold italic text-blue-500 xl:text-right text-center">
                    Fast Charge <br />{" "}
                    <span className="text-3xl sm:text-4xl xl:text-5xl">
                      Anytime. Anywhere.
                    </span>
                  </h1>
                </li>
                <ul className="flex flex-row mx-auto xl:mx-none xl:w-full xl:flex-col xl:justify-end space-x-6 xl:space-x-0 sm:space-x-12 xl:space-y-5 xl:pt-12">
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-right">
                      80%
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-right">
                      in 50mins
                    </p>
                  </li>
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-right">
                      Fast Charge
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-right">
                      Supported Accessory
                    </p>
                  </li>
                </ul>
              </ul>
            </div>
          </div>

          {/* ++++++++++++++++++++++
CONTROLLER
++++++++++++++++++++++ */}

          {/* XS CONTROLLER */}
          <div className="aspect-w-5 aspect-h-11 relative bg-[#101010] sm:hidden pb-0">
            <StaticImage
              src="https://res.cloudinary.com/kabira-mobility/image/upload/v1638798472/Landing%20Page/FrameXS_02_sfk4r6.png"
              alt="KM3000 & KM4000"
              className="aspect-w-5 aspect-h-11 sm:hidden"
            />
            <div className="absolute xl:grid xl:grid-cols-2 xl:grid-rows-2 xl:inset-32 xl:h-auto xl:w-auto">
              <ul className="flex flex-col space-y-4 xl:col-start-1 xl:row-span-2 justify-between">
                <li className="flex flex-col space-y-2 pb-8 xl:pb-0 pt-12 xl:pt-0">
                  <h1 className="text-4xl sm:text-5xl xl:text-6xl xl:leading-tight uppercase font-extrabold italic text-blue-500 xl:text-left text-center">
                    smart foc <br />
                    controller
                  </h1>
                </li>
                <ul className="flex flex-row mx-auto xl:mx-none xl:w-full xl:flex-col xl:justify-end space-x-6 xl:space-x-0 sm:space-x-12 xl:space-y-5 xl:pt-12">
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-left">
                      DeltaEV
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-left">
                      Smart Controller
                    </p>
                  </li>
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-left">
                      35%
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-left">
                      Improved Torque
                    </p>
                  </li>
                  <li className="hidden sm:flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-left">
                      28%
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-left">
                      Improved Efficiency
                    </p>
                  </li>
                </ul>
              </ul>
            </div>
          </div>
          {/* SM CONTROLLER */}
          <div className="aspect-w-9 aspect-h-11 relative bg-[#101010] hidden sm:block md:hidden pb-0">
            <StaticImage
              src="https://res.cloudinary.com/kabira-mobility/image/upload/v1638798473/Landing%20Page/FrameSM_02_edxgxh.png"
              alt="KM3000 & KM4000"
              className="aspect-w-9 aspect-h-11 hidden sm:block md:hidden"
            />

            <div className="absolute xl:grid xl:grid-cols-2 xl:grid-rows-2 xl:inset-32 xl:h-auto xl:w-auto">
              <ul className="flex flex-col space-y-4 xl:col-start-1 xl:row-span-2 justify-between">
                <li className="flex flex-col space-y-2 pb-8 xl:pb-0 pt-12 xl:pt-0">
                  <h1 className="text-4xl sm:text-5xl xl:text-6xl xl:leading-tight uppercase font-extrabold italic text-blue-500 xl:text-left text-center">
                    smart foc <br />
                    controller
                  </h1>
                </li>
                <ul className="flex flex-row mx-auto xl:mx-none xl:w-full xl:flex-col xl:justify-end space-x-6 xl:space-x-0 sm:space-x-12 xl:space-y-5 xl:pt-12">
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-left">
                      DeltaEV
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-left">
                      Smart Controller
                    </p>
                  </li>
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-left">
                      35%
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-left">
                      Improved Torque
                    </p>
                  </li>
                  <li className="hidden sm:flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-left">
                      28%
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-left">
                      Improved Efficiency
                    </p>
                  </li>
                </ul>
              </ul>
            </div>
          </div>
          {/* MD CONTROLLER */}
          <div className="aspect-w-4 aspect-h-4 relative bg-[#101010] hidden md:block lg:hidden pb-0">
            <StaticImage
              src="https://res.cloudinary.com/kabira-mobility/image/upload/v1638798469/Landing%20Page/FrameMD_02_owmkfn.png"
              alt="KM3000 & KM4000"
              className="aspect-w-4 aspect-h-4 hidden md:block lg:hidden"
            />

            <div className="absolute xl:grid xl:grid-cols-2 xl:grid-rows-2 xl:inset-32 xl:h-auto xl:w-auto">
              <ul className="flex flex-col space-y-4 xl:col-start-1 xl:row-span-2 justify-between">
                <li className="flex flex-col space-y-2 pb-8 xl:pb-0 pt-12 xl:pt-0">
                  <h1 className="text-4xl sm:text-5xl xl:text-6xl xl:leading-tight uppercase font-extrabold italic text-blue-500 xl:text-left text-center">
                    smart foc <br />
                    controller
                  </h1>
                </li>
                <ul className="flex flex-row mx-auto xl:mx-none xl:w-full xl:flex-col xl:justify-end space-x-6 xl:space-x-0 sm:space-x-12 xl:space-y-5 xl:pt-12">
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-left">
                      DeltaEV
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-left">
                      Smart Controller
                    </p>
                  </li>
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-left">
                      35%
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-left">
                      Improved Torque
                    </p>
                  </li>
                  <li className="hidden sm:flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-left">
                      28%
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-left">
                      Improved Efficiency
                    </p>
                  </li>
                </ul>
              </ul>
            </div>
          </div>
          {/* LG CONTROLLER */}
          <div className="aspect-w-16 aspect-h-10 relative bg-[#101010] hidden lg:block xl:hidden pb-0">
            <StaticImage
              src="https://res.cloudinary.com/kabira-mobility/image/upload/v1638798830/Landing%20Page/FrameLG_02_bhpjqp.png"
              alt="KM3000 & KM4000"
              className="aspect-w-16 aspect-h-10 hidden lg:block xl:hidden"
            />

            <div className="absolute xl:grid xl:grid-cols-2 xl:grid-rows-2 xl:inset-32 xl:h-auto xl:w-auto">
              <ul className="flex flex-col space-y-4 xl:col-start-1 xl:row-span-2 justify-between">
                <li className="flex flex-col space-y-2 pb-8 xl:pb-0 pt-12 xl:pt-0">
                  <h1 className="text-4xl sm:text-5xl xl:text-6xl xl:leading-tight uppercase font-extrabold italic text-blue-500 xl:text-left text-center">
                    smart foc <br />
                    controller
                  </h1>
                </li>
                <ul className="flex flex-row mx-auto xl:mx-none xl:w-full xl:flex-col xl:justify-end space-x-6 xl:space-x-0 sm:space-x-12 xl:space-y-5 xl:pt-12">
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-left">
                      DeltaEV
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-left">
                      Smart Controller
                    </p>
                  </li>
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-left">
                      35%
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-left">
                      Improved Torque
                    </p>
                  </li>
                  <li className="hidden sm:flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-left">
                      28%
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-left">
                      Improved Efficiency
                    </p>
                  </li>
                </ul>
              </ul>
            </div>
          </div>
          {/* XL CONTROLLER */}
          <div className="aspect-w-16 aspect-h-8 relative bg-[#101010] hidden xl:block pb-0">
            <StaticImage
              src="https://res.cloudinary.com/kabira-mobility/image/upload/v1638798466/Landing%20Page/Frame2XL_02_urtjpb.png"
              alt="KM3000 & KM4000"
              className="aspect-w-16 aspect-h-8 hidden xl:block"
            />
            <div className="absolute xl:grid xl:grid-cols-2 xl:grid-rows-2 xl:inset-32 xl:h-auto xl:w-auto">
              <ul className="flex flex-col space-y-4 xl:col-start-1 xl:row-span-2 justify-between">
                <li className="flex flex-col space-y-2 pb-8 xl:pb-0 pt-12 xl:pt-0">
                  <h1 className="text-4xl sm:text-5xl xl:text-6xl xl:leading-tight uppercase font-extrabold italic text-blue-500 xl:text-left text-center">
                    smart foc <br />
                    controller
                  </h1>
                </li>
                <ul className="flex flex-row mx-auto xl:mx-none xl:w-full xl:flex-col xl:justify-end space-x-6 xl:space-x-0 sm:space-x-12 xl:space-y-5 xl:pt-12">
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-left">
                      DeltaEV
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-left">
                      Smart Controller
                    </p>
                  </li>
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-left">
                      35%
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-left">
                      Improved Torque
                    </p>
                  </li>
                  <li className="hidden sm:flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-left">
                      28%
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-left">
                      Improved Efficiency
                    </p>
                  </li>
                </ul>
              </ul>
            </div>
          </div>

          {/* ++++++++++++++++++++++
BLDC HUB MOTOR
++++++++++++++++++++++ */}

          {/* XS MOTOR */}

          <div className="aspect-w-5 aspect-h-11 relative bg-[#131313] sm:hidden pb-0">
            <StaticImage
              src="https://res.cloudinary.com/kabira-mobility/image/upload/v1638798472/Landing%20Page/FrameXS_03_zstxcy.png"
              alt="KM3000 & KM4000"
              className="aspect-w-5 aspect-h-11 sm:hidden"
            />
            <div className="absolute xl:grid xl:grid-cols-2 xl:grid-rows-2 xl:inset-32 xl:h-auto xl:w-auto">
              <ul className="flex flex-col space-y-4 xl:col-start-2 xl:row-span-2 justify-between">
                <li className="flex flex-col space-y-2 pb-8 xl:pb-0 pt-12 xl:pt-0">
                  <h1 className="text-4xl sm:text-5xl xl:text-6xl xl:leading-tight uppercase font-extrabold italic text-blue-500 xl:text-right text-center">
                    Hi-Performance <br />
                    BLDC Hub Motor
                  </h1>
                </li>
                <ul className="flex flex-row mx-auto xl:mx-none xl:w-full xl:flex-col xl:justify-end space-x-6 xl:space-x-0 sm:space-x-12 xl:space-y-5 xl:pt-12">
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-right">
                      8000w
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-right">
                      Power Output
                    </p>
                  </li>
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-right">
                      120kmph
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-right">
                      Top Speed
                    </p>
                  </li>
                  <li className="hidden sm:flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-right">
                      Twin
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-right">
                      Hall Sensors
                    </p>
                  </li>
                </ul>
              </ul>
            </div>
          </div>
          {/* SM MOTOR */}

          <div className="aspect-w-9 aspect-h-11 relative bg-[#131313] hidden sm:block md:hidden pb-0">
            <StaticImage
              src="https://res.cloudinary.com/kabira-mobility/image/upload/v1638798471/Landing%20Page/FrameSM_03_syad6e.png"
              alt="KM3000 & KM4000"
              className="aspect-w-9 aspect-h-11 hidden sm:block md:hidden"
            />
            <div className="absolute xl:grid xl:grid-cols-2 xl:grid-rows-2 xl:inset-32 xl:h-auto xl:w-auto">
              <ul className="flex flex-col space-y-4 xl:col-start-2 xl:row-span-2 justify-between">
                <li className="flex flex-col space-y-2 pb-8 xl:pb-0 pt-12 xl:pt-0">
                  <h1 className="text-4xl sm:text-5xl xl:text-6xl xl:leading-tight uppercase font-extrabold italic text-blue-500 xl:text-right text-center">
                    Hi-Performance <br />
                    BLDC Hub Motor
                  </h1>
                </li>
                <ul className="flex flex-row mx-auto xl:mx-none xl:w-full xl:flex-col xl:justify-end space-x-6 xl:space-x-0 sm:space-x-12 xl:space-y-5 xl:pt-12">
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-right">
                      8000w
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-right">
                      Power Output
                    </p>
                  </li>
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-right">
                      120kmph
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-right">
                      Top Speed
                    </p>
                  </li>
                  <li className="hidden sm:flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-right">
                      Twin
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-right">
                      Hall Sensors
                    </p>
                  </li>
                </ul>
              </ul>
            </div>
          </div>
          {/* MD MOTOR */}

          <div className="aspect-w-4 aspect-h-4 relative bg-[#131313] hidden md:block lg:hidden pb-0">
            <StaticImage
              src="https://res.cloudinary.com/kabira-mobility/image/upload/v1638798470/Landing%20Page/FrameMD_03_xpqxmg.png"
              alt="KM3000 & KM4000"
              className="aspect-w-4 aspect-h-4 hidden md:block lg:hidden"
            />
            <div className="absolute xl:grid xl:grid-cols-2 xl:grid-rows-2 xl:inset-32 xl:h-auto xl:w-auto">
              <ul className="flex flex-col space-y-4 xl:col-start-2 xl:row-span-2 justify-between">
                <li className="flex flex-col space-y-2 pb-8 xl:pb-0 pt-12 xl:pt-0">
                  <h1 className="text-4xl sm:text-5xl xl:text-6xl xl:leading-tight uppercase font-extrabold italic text-blue-500 xl:text-right text-center">
                    Hi-Performance <br />
                    BLDC Hub Motor
                  </h1>
                </li>
                <ul className="flex flex-row mx-auto xl:mx-none xl:w-full xl:flex-col xl:justify-end space-x-6 xl:space-x-0 sm:space-x-12 xl:space-y-5 xl:pt-12">
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-right">
                      8000w
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-right">
                      Power Output
                    </p>
                  </li>
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-right">
                      120kmph
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-right">
                      Top Speed
                    </p>
                  </li>
                  <li className="hidden sm:flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-right">
                      Twin
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-right">
                      Hall Sensors
                    </p>
                  </li>
                </ul>
              </ul>
            </div>
          </div>
          {/* LG MOTOR */}

          <div className="aspect-w-16 aspect-h-10 relative bg-[#131313] hidden lg:block xl:hidden pb-0">
            <StaticImage
              src="https://res.cloudinary.com/kabira-mobility/image/upload/v1638800847/Landing%20Page/FrameLG_03_obztcm.png"
              alt="KM3000 & KM4000"
              className="aspect-w-16 aspect-h-10 hidden lg:block xl:hidden"
            />
            <div className="absolute xl:grid xl:grid-cols-2 xl:grid-rows-2 xl:inset-32 xl:h-auto xl:w-auto">
              <ul className="flex flex-col space-y-4 xl:col-start-2 xl:row-span-2 justify-between">
                <li className="flex flex-col space-y-2 pb-8 xl:pb-0 pt-12 xl:pt-0">
                  <h1 className="text-4xl sm:text-5xl xl:text-6xl xl:leading-tight uppercase font-extrabold italic text-blue-500 xl:text-right text-center">
                    Hi-Performance <br />
                    BLDC Hub Motor
                  </h1>
                </li>
                <ul className="flex flex-row mx-auto xl:mx-none xl:w-full xl:flex-col xl:justify-end space-x-6 xl:space-x-0 sm:space-x-12 xl:space-y-5 xl:pt-12">
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-right">
                      8000w
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-right">
                      Power Output
                    </p>
                  </li>
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-right">
                      120kmph
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-right">
                      Top Speed
                    </p>
                  </li>
                  <li className="hidden sm:flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-right">
                      Twin
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-right">
                      Hall Sensors
                    </p>
                  </li>
                </ul>
              </ul>
            </div>
          </div>
          {/* XL MOTOR */}

          <div className="aspect-w-16 aspect-h-8 relative bg-[#131313] hidden xl:block pb-0">
            <StaticImage
              src="https://res.cloudinary.com/kabira-mobility/image/upload/v1638798467/Landing%20Page/Frame2XL_03_wrlwgk.png"
              alt="KM3000 & KM4000"
              className="aspect-w-16 aspect-h-8 hidden xl:block"
            />
            <div className="absolute xl:grid xl:grid-cols-2 xl:grid-rows-2 xl:inset-32 xl:h-auto xl:w-auto">
              <ul className="flex flex-col space-y-4 xl:col-start-2 xl:row-span-2 justify-between">
                <li className="flex flex-col space-y-2 pb-8 xl:pb-0 pt-12 xl:pt-0">
                  <h1 className="text-4xl sm:text-5xl xl:text-6xl xl:leading-tight uppercase font-extrabold italic text-blue-500 xl:text-right text-center">
                    Hi-Performance <br />
                    BLDC Hub Motor
                  </h1>
                </li>
                <ul className="flex flex-row mx-auto xl:mx-none xl:w-full xl:flex-col xl:justify-end space-x-6 xl:space-x-0 sm:space-x-12 xl:space-y-5 xl:pt-12">
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-right">
                      8000w
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-right">
                      Power Output
                    </p>
                  </li>
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-right">
                      120kmph
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-right">
                      Top Speed
                    </p>
                  </li>
                  <li className="hidden sm:flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-right">
                      Twin
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-right">
                      Hall Sensors
                    </p>
                  </li>
                </ul>
              </ul>
            </div>
          </div>

          {/* ++++++++++++++++++++++
BATTERY PACK
++++++++++++++++++++++ */}

          {/* XS BATTERY PACK */}
          <div className="aspect-w-5 aspect-h-11 relative bg-[#101010] sm:hidden pb-0">
            <StaticImage
              src="https://res.cloudinary.com/kabira-mobility/image/upload/v1638798471/Landing%20Page/FrameXS_01_xdsvyq.png"
              alt="KM3000 & KM4000"
              className="aspect-w-5 aspect-h-11 sm:hidden"
            />
            <div className="absolute xl:grid xl:grid-cols-2 xl:grid-rows-2 xl:inset-32 xl:h-auto xl:w-auto">
              <ul className="flex flex-col space-y-4 xl:col-start-1 xl:row-span-2 justify-between">
                <li className="flex flex-col space-y-2 pb-8 xl:pb-0 pt-12 xl:pt-0">
                  <h1 className="text-4xl sm:text-5xl xl:text-6xl xl:leading-tight uppercase font-extrabold italic text-blue-500 xl:text-left text-center">
                    Hi-Performance <br />
                    Battery Pack
                  </h1>
                </li>
                <ul className="flex flex-row mx-auto xl:mx-none xl:w-full xl:flex-col xl:justify-end space-x-6 xl:space-x-0 sm:space-x-12 xl:space-y-5 xl:pt-12">
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-left">
                      LiFePO4
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-left">
                      Chemistry
                    </p>
                  </li>
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-left">
                      &gt;3000
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-left">
                      Charge Cycles
                    </p>
                  </li>
                  <li className="hidden sm:flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-left">
                      03 Years
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-left">
                      Warranty
                    </p>
                  </li>
                </ul>
              </ul>
            </div>
          </div>
          {/* SM BATTERY PACK */}
          <div className="aspect-w-9 aspect-h-11 relative bg-[#101010] hidden sm:block md:hidden pb-0">
            <StaticImage
              src="https://res.cloudinary.com/kabira-mobility/image/upload/v1638798471/Landing%20Page/FrameSM_01_eqauys.png"
              alt="KM3000 & KM4000"
              className="aspect-w-9 aspect-h-11 hidden sm:block md:hidden"
            />
            <div className="absolute xl:grid xl:grid-cols-2 xl:grid-rows-2 xl:inset-32 xl:h-auto xl:w-auto">
              <ul className="flex flex-col space-y-4 xl:col-start-1 xl:row-span-2 justify-between">
                <li className="flex flex-col space-y-2 pb-8 xl:pb-0 pt-12 xl:pt-0">
                  <h1 className="text-4xl sm:text-5xl xl:text-6xl xl:leading-tight uppercase font-extrabold italic text-blue-500 xl:text-left text-center">
                    Hi-Performance <br />
                    Battery Pack
                  </h1>
                </li>
                <ul className="flex flex-row mx-auto xl:mx-none xl:w-full xl:flex-col xl:justify-end space-x-6 xl:space-x-0 sm:space-x-12 xl:space-y-5 xl:pt-12">
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-left">
                      LiFePO4
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-left">
                      Chemistry
                    </p>
                  </li>
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-left">
                      &gt;3000
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-left">
                      Charge Cycles
                    </p>
                  </li>
                  <li className="hidden sm:flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-left">
                      03 Years
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-left">
                      Warranty
                    </p>
                  </li>
                </ul>
              </ul>
            </div>
          </div>
          {/* MD BATTERY PACK */}
          <div className="aspect-w-4 aspect-h-4 relative bg-[#101010] hidden md:block lg:hidden pb-0">
            <StaticImage
              src="https://res.cloudinary.com/kabira-mobility/image/upload/v1638798470/Landing%20Page/FrameMD_01_ijcckm.png"
              alt="KM3000 & KM4000"
              className="aspect-w-4 aspect-h-4 hidden md:block lg:hidden"
            />
            <div className="absolute xl:grid xl:grid-cols-2 xl:grid-rows-2 xl:inset-32 xl:h-auto xl:w-auto">
              <ul className="flex flex-col space-y-4 xl:col-start-1 xl:row-span-2 justify-between">
                <li className="flex flex-col space-y-2 pb-8 xl:pb-0 pt-12 xl:pt-0">
                  <h1 className="text-4xl sm:text-5xl xl:text-6xl xl:leading-tight uppercase font-extrabold italic text-blue-500 xl:text-left text-center">
                    Hi-Performance <br />
                    Battery Pack
                  </h1>
                </li>
                <ul className="flex flex-row mx-auto xl:mx-none xl:w-full xl:flex-col xl:justify-end space-x-6 xl:space-x-0 sm:space-x-12 xl:space-y-5 xl:pt-12">
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-left">
                      LiFePO4
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-left">
                      Chemistry
                    </p>
                  </li>
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-left">
                      &gt;3000
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-left">
                      Charge Cycles
                    </p>
                  </li>
                  <li className="hidden sm:flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-left">
                      03 Years
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-left">
                      Warranty
                    </p>
                  </li>
                </ul>
              </ul>
            </div>
          </div>
          {/* LG BATTERY PACK */}
          <div className="aspect-w-16 aspect-h-10 relative bg-[#101010] hidden lg:block xl:hidden pb-0">
            <StaticImage
              src="https://res.cloudinary.com/kabira-mobility/image/upload/v1638798468/Landing%20Page/FrameLG_01_ogoylg.png"
              alt="KM3000 & KM4000"
              className="aspect-w-16 aspect-h-10 hidden lg:block xl:hidden"
            />
            <div className="absolute xl:grid xl:grid-cols-2 xl:grid-rows-2 xl:inset-32 xl:h-auto xl:w-auto">
              <ul className="flex flex-col space-y-4 xl:col-start-1 xl:row-span-2 justify-between">
                <li className="flex flex-col space-y-2 pb-8 xl:pb-0 pt-12 xl:pt-0">
                  <h1 className="text-4xl sm:text-5xl xl:text-6xl xl:leading-tight uppercase font-extrabold italic text-blue-500 xl:text-left text-center">
                    Hi-Performance <br />
                    Battery Pack
                  </h1>
                </li>
                <ul className="flex flex-row mx-auto xl:mx-none xl:w-full xl:flex-col xl:justify-end space-x-6 xl:space-x-0 sm:space-x-12 xl:space-y-5 xl:pt-12">
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-left">
                      LiFePO4
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-left">
                      Chemistry
                    </p>
                  </li>
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-left">
                      &gt;3000
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-left">
                      Charge Cycles
                    </p>
                  </li>
                  <li className="hidden sm:flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-left">
                      03 Years
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-left">
                      Warranty
                    </p>
                  </li>
                </ul>
              </ul>
            </div>
          </div>
          {/* XL BATTERY PACK */}
          <div className="aspect-w-16 aspect-h-8 relative bg-[#101010] hidden xl:block pb-0">
            <StaticImage
              src="https://res.cloudinary.com/kabira-mobility/image/upload/v1638798468/Landing%20Page/Frame2XL_01_xerbnj.png"
              alt="KM3000 & KM4000"
              className="aspect-w-16 aspect-h-8 hidden xl:block"
            />
            <div className="absolute xl:grid xl:grid-cols-2 xl:grid-rows-2 xl:inset-32 xl:h-auto xl:w-auto">
              <ul className="flex flex-col space-y-4 xl:col-start-1 xl:row-span-2 justify-between">
                <li className="flex flex-col space-y-2 pb-8 xl:pb-0 pt-12 xl:pt-0">
                  <h1 className="text-4xl sm:text-5xl xl:text-6xl xl:leading-tight uppercase font-extrabold italic text-blue-500 xl:text-left text-center">
                    Hi-Performance <br />
                    Battery Pack
                  </h1>
                </li>
                <ul className="flex flex-row mx-auto xl:mx-none xl:w-full xl:flex-col xl:justify-end space-x-6 xl:space-x-0 sm:space-x-12 xl:space-y-5 xl:pt-12">
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-left">
                      LiFePO4
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-left">
                      Chemistry
                    </p>
                  </li>
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-left">
                      &gt;3000
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-left">
                      Charge Cycles
                    </p>
                  </li>
                  <li className="hidden sm:flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-left">
                      03 Years
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-left">
                      Warranty
                    </p>
                  </li>
                </ul>
              </ul>
            </div>
          </div>
          {/* ++++++++++++++++++++++
CHASSIS
++++++++++++++++++++++ */}

          {/* CHASSIS XS */}
          <div className="aspect-w-5 aspect-h-11 relative bg-[#131313] sm:hidden pb-0">
            <StaticImage
              src="https://res.cloudinary.com/kabira-mobility/image/upload/v1638801759/Landing%20Page/FrameXS_Chassis_5_offayd.png"
              alt="KM3000 & KM4000"
              className="aspect-w-5 aspect-h-11 sm:hidden"
            />
            <div className="absolute xl:grid xl:grid-cols-2 xl:grid-rows-2 xl:inset-32 xl:h-auto xl:w-auto">
              <ul className="flex flex-col space-y-4 xl:col-start-2 xl:row-span-2 justify-between">
                <li className="flex flex-col space-y-2 pb-8 xl:pb-0 pt-12 xl:pt-0">
                  <h1 className="text-4xl sm:text-5xl xl:text-6xl xl:leading-tight uppercase font-extrabold italic text-blue-500 xl:text-right text-center">
                    Hi-Performance <br />
                    Tubular Chassis
                  </h1>
                </li>
                <ul className="flex flex-row mx-auto xl:mx-none xl:w-full xl:flex-col xl:justify-end space-x-6 xl:space-x-0 sm:space-x-12 xl:space-y-5 xl:pt-12">
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-right">
                      Alloy
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-right">
                      Steel Chassis
                    </p>
                  </li>
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-right py-3">
                      13% Stronger
                    </h3>
                  </li>
                  <li className="hidden sm:flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-right py-3">
                      18% Lighter
                    </h3>
                  </li>
                </ul>
              </ul>
            </div>
          </div>
          {/* CHASSIS SM */}
          <div className="aspect-w-9 aspect-h-11 relative bg-[#131313] hidden sm:block md:hidden pb-0">
            <StaticImage
              src="https://res.cloudinary.com/kabira-mobility/image/upload/v1638801759/Landing%20Page/FrameSM_Chassis_5_vywk1b.png"
              alt="KM3000 & KM4000"
              className="aspect-w-9 aspect-h-11 hidden sm:block md:hidden"
            />
            <div className="absolute xl:grid xl:grid-cols-2 xl:grid-rows-2 xl:inset-32 xl:h-auto xl:w-auto">
              <ul className="flex flex-col space-y-4 xl:col-start-2 xl:row-span-2 justify-between">
                <li className="flex flex-col space-y-2 pb-8 xl:pb-0 pt-12 xl:pt-0">
                  <h1 className="text-4xl sm:text-5xl xl:text-6xl xl:leading-tight uppercase font-extrabold italic text-blue-500 xl:text-right text-center">
                    Hi-Performance <br />
                    Tubular Chassis
                  </h1>
                </li>
                <ul className="flex flex-row mx-auto xl:mx-none xl:w-full xl:flex-col xl:justify-end space-x-6 xl:space-x-0 sm:space-x-12 xl:space-y-5 xl:pt-12">
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-right">
                      Alloy
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-right">
                      Steel Chassis
                    </p>
                  </li>
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-right py-3">
                      13% Stronger
                    </h3>
                  </li>
                  <li className="hidden sm:flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-right py-3">
                      18% Lighter
                    </h3>
                  </li>
                </ul>
              </ul>
            </div>
          </div>
          {/* CHASSIS MD */}
          <div className="aspect-w-4 aspect-h-4 relative bg-[#131313] hidden md:block lg:hidden pb-0">
            <StaticImage
              src="https://res.cloudinary.com/kabira-mobility/image/upload/v1638801758/Landing%20Page/FrameMD_Chassis_5_max4q8.png"
              alt="KM3000 & KM4000"
              className="aspect-w-4 aspect-h-4 hidden md:block lg:hidden"
            />
            <div className="absolute xl:grid xl:grid-cols-2 xl:grid-rows-2 xl:inset-32 xl:h-auto xl:w-auto">
              <ul className="flex flex-col space-y-4 xl:col-start-2 xl:row-span-2 justify-between">
                <li className="flex flex-col space-y-2 pb-8 xl:pb-0 pt-12 xl:pt-0">
                  <h1 className="text-4xl sm:text-5xl xl:text-6xl xl:leading-tight uppercase font-extrabold italic text-blue-500 xl:text-right text-center">
                    Hi-Performance <br />
                    Tubular Chassis
                  </h1>
                </li>
                <ul className="flex flex-row mx-auto xl:mx-none xl:w-full xl:flex-col xl:justify-end space-x-6 xl:space-x-0 sm:space-x-12 xl:space-y-5 xl:pt-12">
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-right">
                      Alloy
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-right">
                      Steel Chassis
                    </p>
                  </li>
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-right py-3">
                      13% Stronger
                    </h3>
                  </li>
                  <li className="hidden sm:flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-right py-3">
                      18% Lighter
                    </h3>
                  </li>
                </ul>
              </ul>
            </div>
          </div>
          {/* CHASSIS LG */}
          <div className="aspect-w-16 aspect-h-10 relative bg-[#131313] hidden lg:block xl:hidden pb-0">
            <StaticImage
              src="https://res.cloudinary.com/kabira-mobility/image/upload/v1638801758/Landing%20Page/FrameLG_Chassis_5_nsjplm.png"
              alt="KM3000 & KM4000"
              className="aspect-w-16 aspect-h-10 hidden lg:block xl:hidden"
            />
            <div className="absolute xl:grid xl:grid-cols-2 xl:grid-rows-2 xl:inset-32 xl:h-auto xl:w-auto">
              <ul className="flex flex-col space-y-4 xl:col-start-2 xl:row-span-2 justify-between">
                <li className="flex flex-col space-y-2 pb-8 xl:pb-0 pt-12 xl:pt-0">
                  <h1 className="text-4xl sm:text-5xl xl:text-6xl xl:leading-tight uppercase font-extrabold italic text-blue-500 xl:text-right text-center">
                    Hi-Performance <br />
                    Tubular Chassis
                  </h1>
                </li>
                <ul className="flex flex-row mx-auto xl:mx-none xl:w-full xl:flex-col xl:justify-end space-x-6 xl:space-x-0 sm:space-x-12 xl:space-y-5 xl:pt-12">
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-right">
                      Alloy
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-right">
                      Steel Chassis
                    </p>
                  </li>
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-right py-3">
                      13% Stronger
                    </h3>
                  </li>
                  <li className="hidden sm:flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-right py-3">
                      18% Lighter
                    </h3>
                  </li>
                </ul>
              </ul>
            </div>
          </div>
          {/* CHASSIS XL */}
          <div className="aspect-w-16 aspect-h-8 relative bg-[#131313] hidden xl:block pb-0">
            <StaticImage
              src="https://res.cloudinary.com/kabira-mobility/image/upload/v1638801759/Landing%20Page/Frame2XL_Chassis_5_c8x7mk.png"
              alt="KM3000 & KM4000"
              className="aspect-w-16 aspect-h-8 hidden xl:block"
            />
            <div className="absolute xl:grid xl:grid-cols-2 xl:grid-rows-2 xl:inset-32 xl:h-auto xl:w-auto">
              <ul className="flex flex-col space-y-4 xl:col-start-2 xl:row-span-2 justify-between">
                <li className="flex flex-col space-y-2 pb-8 xl:pb-0 pt-12 xl:pt-0">
                  <h1 className="text-4xl sm:text-5xl xl:text-6xl xl:leading-tight uppercase font-extrabold italic text-blue-500 xl:text-right text-center">
                    Hi-Performance <br />
                    Tubular Chassis
                  </h1>
                </li>
                <ul className="flex flex-row mx-auto xl:mx-none xl:w-full xl:flex-col xl:justify-end space-x-6 xl:space-x-0 sm:space-x-12 xl:space-y-5 xl:pt-12">
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-right">
                      Alloy
                    </h3>
                    <p className="text-base sm:text-xl xl:text-2xl text-gray-500 italic text-center xl:text-right">
                      Steel Chassis
                    </p>
                  </li>
                  <li className="flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-right py-3">
                      13% Stronger
                    </h3>
                  </li>
                  <li className="hidden sm:flex flex-col space-y-2 xl:space-y-2 2xl:space-y-4">
                    <h3 className="text-3xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-semibold italic text-gray-300 text-center xl:text-right py-3">
                      18% Lighter
                    </h3>
                  </li>
                </ul>
              </ul>
            </div>
          </div>
          <section className="mt-8 grid bg-gray-800 grid-cols-[16px,5fr,4px,3fr,3fr,16px] md:grid-cols-[1fr,3fr,4px,1.5fr,1.5fr,1fr] gap-[1px] border-t-[1px] border-b-[1px] border-gray-800">
            <div className="py-2 bg-gray-900"></div>
            <div className="col-span-4 bg-gray-900">
              <div className="flex flex-col space-y-3 md:space-y-4 bg-gray-900 py-6 px-4 sm:px-8 md:px-12">
                <h2 className="text-2xl md:text-3xl leading-normal text-white">
                  Technical Specification
                </h2>
              </div>
            </div>
            <div className="py-2 bg-gray-900"></div>
            <div className="py-2 bg-gray-900"></div>
            <div className="py-3 pl-4 sm:pl-8 md:pl-12 bg-gray-900">
              <p className="text-sm sm:text-base md:text-lg text-gray-300 font-light"></p>
            </div>
            <div className="py-2 bg-gray-900"></div>
            <div className="py-3 pl-4 sm:pl-8 md:pl-12 bg-gray-900">
              <p className="text-sm sm:text-base md:text-lg text-blue-400 font-medium">
                KM3000
              </p>
            </div>
            <div className="py-3 pl-4 sm:pl-8 md:pl-12 bg-gray-900">
              <p className="text-sm sm:text-base md:text-lg text-blue-400 font-medium">
                KM4000
              </p>
            </div>
            <div className="py-2 bg-gray-900"></div>
            <div className="py-2 bg-gray-900"></div>
            <div className="py-3 pl-4 sm:pl-8 md:pl-12 bg-gray-900">
              <p className="text-sm sm:text-base md:text-lg text-gray-300 font-light">
                Top Speed
              </p>
            </div>
            <div className="py-2 bg-gray-900"></div>
            <div className="py-3 pl-4 sm:pl-8 md:pl-12 bg-gray-900">
              <p className="text-sm sm:text-base md:text-lg text-gray-400 font-light">
                100kmph
              </p>
            </div>
            <div className="py-3 pl-4 sm:pl-8 md:pl-12 bg-gray-900">
              <p className="text-sm sm:text-base md:text-lg text-gray-400 font-light">
                120kmph
              </p>
            </div>
            <div className="py-2 bg-gray-900"></div>
            <div className="py-2 bg-gray-900"></div>
            <div className="py-3 pl-4 sm:pl-8 md:pl-12 bg-gray-900">
              <p className="text-sm sm:text-base md:text-lg text-gray-300 font-light">
                Range
              </p>
            </div>
            <div className="py-2 bg-gray-900"></div>
            <div className="py-3 pl-4 sm:pl-8 md:pl-12 bg-gray-900">
              <p className="text-sm sm:text-base md:text-lg text-gray-400 font-light">
                120km
              </p>
            </div>
            <div className="py-3 pl-4 sm:pl-8 md:pl-12 bg-gray-900">
              <p className="text-sm sm:text-base md:text-lg text-gray-400 font-light">
                150km
              </p>
            </div>
            <div className="py-2 bg-gray-900"></div>
            <div className="py-2 bg-gray-900"></div>
            <div className="py-3 pl-4 sm:pl-8 md:pl-12 bg-gray-900">
              <p className="text-sm sm:text-base md:text-lg text-gray-300 font-light">
                Power
              </p>
            </div>
            <div className="py-2 bg-gray-900"></div>
            <div className="py-3 pl-4 sm:pl-8 md:pl-12 bg-gray-900">
              <p className="text-sm sm:text-base md:text-lg text-gray-400 font-light">
                3000W
              </p>
            </div>
            <div className="py-3 pl-4 sm:pl-8 md:pl-12 bg-gray-900">
              <p className="text-sm sm:text-base md:text-lg text-gray-400 font-light">
                5000W
              </p>
            </div>
            <div className="py-2 bg-gray-900"></div>
            <div className="py-2 bg-gray-900"></div>
            <div className="py-3 pl-4 sm:pl-8 md:pl-12 bg-gray-900">
              <p className="text-sm sm:text-base md:text-lg text-gray-300 font-light">
                Battery
              </p>
            </div>
            <div className="py-2 bg-gray-900"></div>
            <div className="py-3 pl-4 sm:pl-8 md:pl-12 bg-gray-900">
              <p className="text-sm sm:text-base md:text-lg text-gray-400 font-light">
                3.3KwH
              </p>
            </div>
            <div className="py-3 pl-4 sm:pl-8 md:pl-12 bg-gray-900">
              <p className="text-sm sm:text-base md:text-lg text-gray-400 font-light">
                4.4KwH
              </p>
            </div>
            <div className="py-2 bg-gray-900"></div>
            <div className="py-2 bg-gray-900"></div>
            <div className="py-3 pl-4 sm:pl-8 md:pl-12 bg-gray-900">
              <p className="text-sm sm:text-base md:text-lg text-gray-300 font-light">
                Controller
              </p>
            </div>
            <div className="py-2 bg-gray-900"></div>
            <div className="py-3 pl-4 col-span-2 sm:pl-8 md:pl-12 bg-gray-900">
              <p className="text-sm col-span-2 sm:text-base md:text-lg text-gray-400 font-light">
                DeltaEV Controller
              </p>
            </div>
            <div className="py-2 bg-gray-900"></div>
            <div className="py-2 bg-gray-900"></div>
            <div className="py-3 pl-4 sm:pl-8 md:pl-12 bg-gray-900">
              <p className="text-sm sm:text-base md:text-lg text-gray-300 font-light">
                Acceleration (0-60)
              </p>
            </div>
            <div className="py-2 bg-gray-900"></div>
            <div className="py-3 pl-4 sm:pl-8 md:pl-12 bg-gray-900">
              <p className="text-sm sm:text-base md:text-lg text-gray-400 font-light">
                3.2s
              </p>
            </div>
            <div className="py-3 pl-4 sm:pl-8 md:pl-12 bg-gray-900">
              <p className="text-sm sm:text-base md:text-lg text-gray-400 font-light">
                3.1s
              </p>
            </div>
            <div className="py-2 bg-gray-900"></div>
            <div className="py-2 bg-gray-900"></div>
            <div className="py-3 pl-4 sm:pl-8 md:pl-12 bg-gray-900">
              <p className="text-sm sm:text-base md:text-lg text-gray-300 font-light">
                Brakes
              </p>
            </div>
            <div className="py-2 bg-gray-900"></div>
            <div className="py-3 col-span-2 pl-4 sm:pl-8 md:pl-12 bg-gray-900">
              <p className="text-sm sm:text-base md:text-lg text-gray-400 font-light">
                Disc Brakes
              </p>
            </div>
            <div className="py-2 bg-gray-900"></div>
            <div className="py-2 bg-gray-900"></div>
            <div className="py-3 pl-4 sm:pl-8 md:pl-12 bg-gray-900">
              <p className="text-sm sm:text-base md:text-lg text-gray-300 font-light">
                Dashboard
              </p>
            </div>
            <div className="py-2 bg-gray-900"></div>
            <div className="py-3 col-span-2 pl-4 sm:pl-8 md:pl-12 bg-gray-900">
              <p className="text-sm sm:text-base md:text-lg text-gray-400 font-light">
                Smart Dashboard
              </p>
            </div>
            <div className="py-2 bg-gray-900"></div>
            <div className="py-2 bg-gray-900"></div>
            <div className="py-3 pl-4 sm:pl-8 md:pl-12 bg-gray-900">
              <p className="text-sm sm:text-base md:text-lg text-gray-300 font-light">
                Tyre
              </p>
            </div>
            <div className="py-2 bg-gray-900"></div>
            <div className="py-3 pl-4 col-span-2 sm:pl-8 md:pl-12 bg-gray-900">
              <p className="text-sm sm:text-base md:text-lg text-gray-400 font-light">
                17" Tyre
              </p>
            </div>

            <div className="py-2 bg-gray-900"></div>
            <div className="py-2 bg-gray-900"></div>
            <div className="py-2 bg-gray-900 col-span-4 px-4 sm:px-8 md:px-12">
              <div className="py-4 flex flex-col sm:flex-row sm:justify-between invisible">
                <p className="text-2xl text-gray-300 font-medium py-3 text-center sm:text-left">
                  Need more Information?
                </p>
                <button className="button-icon">
                  <p className="text-base text-gray-800">Download Catalog</p>
                  <DownloadIcon className="h-6 text-gray-800" />
                </button>
              </div>
            </div>
            <div className="py-2 bg-gray-900"></div>
          </section>
        </main>
      </Layout>
    </>
  );
};

export default IndexPage;
